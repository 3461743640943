<template>
    <!--tab栏-->
    <div>
        <!--填充高度-->
        <div style="height:11vh;"></div>

        <!--主体-->
        <div class="tabbar_body" v-if="quanxain!=3">
            <div v-for="(item,index) in tabbar_list" :key="item.name" class="every_tab" @click="changeTab(item.url)"> 
                <img :src="choose_index==index?item.icon_xuan:item.icon_wei" style="width:5vw;height:5vw;"> 
                <div v-if="user_type2&&index==1" class="tabbar_font" :style="choose_index==index?'color:#04A9F3;':''">区域看板</div>
                <div v-else class="tabbar_font" :style="choose_index==index?'color:#04A9F3;':''">{{type==1?item.name:item.name2}}</div>
            </div>
        </div>

        <div class="tabbar_body" v-if="quanxain==3">
            <div v-for="(item,index) in tabbar_list1" :key="item.name" class="every_tab" @click="changeTab(item.url)"> 
                <img :src="choose_index==index?item.icon_xuan:item.icon_wei" style="width:5vw;height:5vw;"> 
                <div v-if="user_type2&&index==1" class="tabbar_font" :style="choose_index==index?'color:#04A9F3;':''">区域看板</div>
                <div v-else class="tabbar_font" :style="choose_index==index?'color:#04A9F3;':''">{{type==1?item.name:item.name2}}</div>
            </div>
        </div>

        <div class="tabbar_body" v-if="quanxain==7&&quanxain!=3">
            <div v-for="(item,index) in tabbar_listsSe" :key="item.name" class="every_tab" @click="changeTab(item.url)"> 
                <img :src="choose_index==index?item.icon_xuan:item.icon_wei" style="width:5vw;height:5vw;"> 
                <div v-if="user_type2&&index==1" class="tabbar_font" :style="choose_index==index?'color:#04A9F3;':''">区域看板</div>
                <div v-else class="tabbar_font" :style="choose_index==index?'color:#04A9F3;':''">{{type==1?item.name:item.name2}}</div>
            </div>
        </div>
        <div class="tabbar_body" v-if="quanxain=='11'">
            <div v-for="(item,index) in tabbar_listsSe2" :key="item.name" class="every_tab" @click="changeTab(item.url)"> 
                <img :src="choose_index==index?item.icon_xuan:item.icon_wei" style="width:5vw;height:5vw;"> 
                <div v-if="user_type2&&index==1" class="tabbar_font" :style="choose_index==index?'color:#04A9F3;':''">区域看板</div>
                <div v-else class="tabbar_font" :style="choose_index==index?'color:#04A9F3;':''">{{type==1?item.name:item.name2}}</div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
  props: {
    choose_index:{
        type:Number,
        default: 1,
    },
    type:{
        type:Number,
        default: 1, 
    },
    user_type2:{//是否是区域账号
        type:Boolean,
        default:false,
    },
  },
  data() {
    return {
        quanxain:3,
        roleId:[],
        tabbar_list:[
            {name:'经营分析',name2:'经营分析',url:'form',icon_wei:require('@/assets/tabbar/jingyingfenxi_wei.png'),icon_xuan:require('@/assets/tabbar/jingyingfenxi_xuan.png')},
            {name:'总部看板',name2:'门店看板',name3:'区域看板',url:'home?pagetype=1',icon_wei:require('@/assets/tabbar/zongbukanban_wei.png'),icon_xuan:require('@/assets/tabbar/zongbukanban_xuan.png')},
            // {name:'门店看板',url:'home?pagetype=2',icon_wei:require('@/assets/tabbar/mendiankanban_wei.png'),icon_xuan:require('@/assets/tabbar/mendiankanban_xuan.png')},
            {name:'盘点',name2:'盘点',url:'point',icon_wei:require('@/assets/tabbar/point.png'),icon_xuan:require('@/assets/tabbar/point.png')},
            // {name:'服务中心',name2:'服务中心',name3:'服务中心',url:'service?pagetype=3',icon_wei:require('@/assets/tabbar/zongbukanban_wei.png'),icon_xuan:require('@/assets/tabbar/zongbukanban_xuan.png')},
            {name:'我的',name2:'我的',url:'my?pagetype=4',icon_wei:require('@/assets/tabbar/wode_wei.png'),icon_xuan:require('@/assets/tabbar/wode_xuan.png')},
        ],
        tabbar_listsSe:[
           
            {name:'服务中心',name2:'服务中心',name3:'服务中心',url:'service?pagetype=0',icon_wei:require('@/assets/tabbar/zongbukanban_wei.png'),icon_xuan:require('@/assets/tabbar/zongbukanban_xuan.png')},
            {name:'我的',name2:'我的',url:'my?pagetype=1',icon_wei:require('@/assets/tabbar/wode_wei.png'),icon_xuan:require('@/assets/tabbar/wode_xuan.png')},
        ],
        tabbar_listsSe2:[
           
            {name:'总部看板',name2:'总部看板',name3:'总部看板',url:'service1?pagetype=0',icon_wei:require('@/assets/tabbar/zongbukanban_wei.png'),icon_xuan:require('@/assets/tabbar/zongbukanban_xuan.png')},
            {name:'门店处方',name2:'门店处方',name3:'门店处方',url:'service5?pagetype=1',icon_wei:require('@/assets/tabbar/zongbukanban_wei.png'),icon_xuan:require('@/assets/tabbar/zongbukanban_xuan.png')},
            {name:'中医看板',name2:'中医看板',name3:'中医看板',url:'service2?pagetype=2',icon_wei:require('@/assets/tabbar/zy.png'),icon_xuan:require('@/assets/tabbar/zongbukanban_xuan.png')},
            {name:'患者回访',name2:'患者回访',url:'service3?pagetype=3',icon_wei:require('@/assets/tabbar/point.png'),icon_xuan:require('@/assets/tabbar/point.png')},
            {name:'我的',name2:'我的',url:'my?pagetype=4',icon_wei:require('@/assets/tabbar/wode_wei.png'),icon_xuan:require('@/assets/tabbar/wode_xuan.png')},
        ],
        tabbar_list1:[
            // {name:'经营分析',name2:'经营分析',url:'form',icon_wei:require('@/assets/tabbar/jingyingfenxi_wei.png'),icon_xuan:require('@/assets/tabbar/jingyingfenxi_xuan.png')},
            // {name:'总部看板',name2:'门店看板',name3:'区域看板',url:'home?pagetype=1',icon_wei:require('@/assets/tabbar/zongbukanban_wei.png'),icon_xuan:require('@/assets/tabbar/zongbukanban_xuan.png')},
            // {name:'门店看板',url:'home?pagetype=2',icon_wei:require('@/assets/tabbar/mendiankanban_wei.png'),icon_xuan:require('@/assets/tabbar/mendiankanban_xuan.png')},
            {name:'盘点',name2:'盘点',url:'point',icon_wei:require('@/assets/tabbar/point.png'),icon_xuan:require('@/assets/tabbar/point.png')},
            {name:'我的',name2:'我的',url:'my',icon_wei:require('@/assets/tabbar/wode_wei.png'),icon_xuan:require('@/assets/tabbar/wode_xuan.png')},
        ],
      
       
    };
  },
  created(){
   
   this.user_api()
   
  
  },
  methods:{
      changeTab(url){
       
          this.$router.push('./' + url);
        //   this.user_api()
      },
      user_api(){
      this.quanxain= localStorage.getItem('quanxian')
      console.log(this.quanxain)
      this.$axios.post(this.$api.user_api).then(res=>{   
       this.roleId=res.data.data.roleId
       if(res.data.data.type =='11'){
         
          
            this.quanxain =res.data.data.type
          
        }else{
            console.log(this.roleId,"aaaaaaaaa");
       this.roleId.forEach(element => {
          if(this.roleId.length===1 && element===3){
           console.log(element,"element")
           this.quanxain=element
          }else if(this.roleId.length===1 && element===7){
           console.log(element,"element")
           this.quanxain=element
          }
          else{
            this.quanxain=1
          }
          
       });
        }
    
  
       console.log(this.roleId,"this.roleId")
      })
    },
  }
}
</script>
<style scoped>
.tabbar_body{
    height: 9vh;
    width: 100vw;
    background-color:#EFEFEF;
    position:fixed;
    bottom: 0;
    left:0;
    z-index: 20;
    display: flex;
    justify-content: space-around;
}
.every_tab{
    width:20vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.tabbar_font{
    font-size:3.2vw;
    margin-top:0.8vh;
}
</style>